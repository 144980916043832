<template>
	<div>
		<ion-item>
			<div tabindex="0"></div><!-- bug fix for safari 14 -->
			<ion-input v-model="keyword" type="text" placeholder="Enter category"></ion-input>
		</ion-item>		
		<!-- Categories -->
		<div v-if="matchedItems.length > 0">
			<ion-list>
				<ion-item v-for="item in matchedItems" :key="item" @click.prevent="dataUp(item)">
					<div tabindex="0"></div>
					{{item.name}}
				</ion-item>
			</ion-list>
		</div>
		<div v-if="addButton === true">
			<ion-item lines="none">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-button slot="end" @click.prevent="storeCategory()">
					Add Category
				</ion-button>
			</ion-item>	
		</div>
		<div v-if="matchedItems.length < 1 && addButton === false">
			<ion-list v-if="categories.length > 0">
				<ion-item v-for="category in categories" :key="category" @click.prevent="dataUp(category)">
					<div tabindex="0"></div>
					{{category.name}}
				</ion-item>
			</ion-list>
		</div>
	</div>
</template>

<script>
import { IonList, IonItem, IonInput, IonButton, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';

export default defineComponent({
	name: 'AdminCategoryList',
	props: [],
	components: {
		IonList, IonItem, IonInput, IonButton
	},
	setup() {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const keyword = ref(null);
		const categories = ref([]);
		const matchedItems = ref([]);
		const addButton = ref(false);
		const isError = ref(false);
		const errors = ref([]);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }

		onMounted(() => {
			getCategories()
		})

		function getCategories() {
			//presentLoading()
			apiClient.get('/api/get/categories', {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				categories.value = response.data
			}).catch(error => {
				console.log(error)  
			});      
		}

		function searchCategories() {
			if(categories.value.length > 0) {
				matchedItems.value = []
				for(var i=0;i<categories.value.length;i++) {
					let catName = categories.value[i].name.toLowerCase()
					if(catName.includes(keyword.value.toLowerCase())) {
						matchedItems.value.push(categories.value[i])
					}
				}
			}
		}

		function storeCategory() {
			presentLoading()
			apiClient.post('/api/post/category', 
			{
				name: keyword.value,  
				status: 1,
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					toastMessage.value = 'You have saved a category.'
					openToast()
					isError.value = false
					errors.value = []
					this.dataUp({
						id: response.data.category.id,
						name: response.data.category.name,
						status: response.data.category.status
					})
					keyword.value = null
					matchedItems.value = []
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That category already exists.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			}); 
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		return {
			authUser, categories, keyword, searchCategories, matchedItems, addButton, storeCategory, isError, errors
		}
	},
	methods: {
		dataUp: function(item) {
			this.$emit('get-category', {
				id: item.id,
				name: item.name,
				slug: item.slug
			})
		}
	},
	watch: {
		keyword: function() {
			if(this.keyword != null && this.keyword != '') {
				this.searchCategories()
				if(this.keyword.length >= 2 && this.matchedItems.length < 1) {
					this.addButton = true
				} else {
					this.addButton = false
				}
			} else {
				this.addButton = false
			}
		}
	}
});
</script>