<template>
	<div>
		<!--{{category}} | {{addressRequired}}| {{name}} | {{step}} | {{pageId}}-->
		<!--{{scenes}} | {{states}} | {{regions}} | {{countries}}{{categoryId}} | {{sceneId}}-->
		<!-- 
			Category 
		-->
		<div v-if="step === 1">
			<!-- Label -->
			<div class="padding-top-md padding-left-md padding-right-md">
				Enter or select a category for this page
			</div>
			<!-- Component -->
			<CategoryList @get-category="getData" />
		</div>
		<!-- 
			Name
		-->
		<div v-if="step === 2">
			<!-- Street Address Required -->
			<ion-item v-if="addressRequired === true && isPageFormValidated === false">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-text color="danger">Full address is required for this type of page.</ion-text>
			</ion-item>	
			<!-- Name -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="name" type="text" placeholder="Name*"></ion-input>
			</ion-item>	
			<!-- Description -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-textarea v-model="description" type="text" placeholder="Description"></ion-textarea>
			</ion-item>	
			<!-- Country -->
			<div v-if="countries.length > 0" class="padding-top-md">
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-label position="stacked">Country</ion-label>
					<ion-select placeholder="Select One" v-model="country">
						<ion-select-option v-for="item in countries" :key="item" :value="item.slug.toLowerCase()">{{item.name}}</ion-select-option>
					</ion-select>
				</ion-item>
			</div>
			<!-- Street Address -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="streetAddress" type="text" placeholder="Street Address"></ion-input>
			</ion-item>	
			<!-- City -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="city" type="text" placeholder="City"></ion-input>
			</ion-item>	
			<!-- Scene -->
			<div v-if="scenes.length > 0 && country == 'us'" class="padding-top-md">
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-label position="stacked">Scene</ion-label>
					<ion-select placeholder="Select One" v-model="sceneId">
						<ion-select-option v-for="item in scenes" :key="item" :value="item.id">{{item.name}}, {{item.state}}</ion-select-option>
					</ion-select>
				</ion-item>
			</div>
			<!-- State -->
			<div v-if="states.length > 0 && country == 'us'" class="padding-top-md">
				<ion-item>
					<div tabindex="0"></div><!-- bug fix for safari 14 -->
					<ion-label position="stacked">State</ion-label>
					<ion-select placeholder="Select One" v-model="state">
						<ion-select-option v-for="item in states" :key="item" :value="item.state.toLowerCase()">{{item.name}}</ion-select-option>
					</ion-select>
				</ion-item>
			</div>
			<!-- Zip -->
			<ion-item v-if="country == 'us'">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="zipCode" type="text" placeholder="Zip code"></ion-input>
			</ion-item>	
			<!-- URL -->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-input v-model="url" type="text" placeholder="Website"></ion-input>
			</ion-item>	
			<!-- 
				Status 
			-->
			<ion-item>
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-label position="stacked">Status</ion-label>
				<ion-select placeholder="Select One" v-model="status">
					<ion-select-option :value="1">Published</ion-select-option>
					<ion-select-option :value="0">Unpublished</ion-select-option>
					<ion-select-option v-if="id > 0" :value="2">Delete</ion-select-option>
				</ion-select>
			</ion-item>
			<!-- Submit -->
			<ion-item v-if="isPageFormValidated === true">
				<div tabindex="0"></div><!-- bug fix for safari 14 -->
				<ion-button slot="end" @click.prevent="storePage()">
					Save
				</ion-button>
			</ion-item>	
		</div>
		<!-- 
			Image
		-->
		<div v-if="step == 3">
			<div v-if="pageId > 0">
				<PagePhoto :page-id="pageId" :page-img="pageImg" @next-step="getStep" post-type="create" />
			</div>
		</div>	
		<!-- 
			Genres
		-->
		<div v-if="step === 4" class="text-center padding-md">
			<ion-button href="/admin-pages">
				Skip
			</ion-button>			
			<PageGenres :page-id="pageId" post-type="create" />
		</div>		
	</div>
</template>

<script>
import { IonItem, IonInput, IonTextarea, IonLabel, IonButton, IonSelect, IonSelectOption, IonText, loadingController, toastController } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import { image, trash, link } from 'ionicons/icons';
import CategoryList from './CategoryList.vue';
import PagePhoto from './PagePhoto.vue';
import PageGenres from './PageGenres.vue';
import apiClient from '../services/api';

export default defineComponent({
	name: 'AdminPageForm',
	props: ['id'],
	components: {
		IonItem, IonInput, IonButton, IonTextarea, IonLabel, IonSelect, IonSelectOption, IonText, CategoryList, PagePhoto, PageGenres
	},
	setup(props) {
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const category = ref(null);
		const categoryId = ref(0);
		const step = ref(1);
		const name = ref(null);
		const description = ref(null);
		const streetAddress = ref(null);
		const city = ref(null);
		const sceneId = ref(0);
		const state = ref(null);
		const country = ref('us');
		const zipCode = ref(null);
		const url = ref(null);
		const status = ref(1);
		const isPageFormValidated = ref(false);
		const toastMessage = ref(null);
		const timeout = { default: 6000 }
		const isError = ref(false);
		const errors = ref([]);
		const addressRequired = ref(false);
		const scenes = ref([]);
		const states = ref([]);
		const regions = ref([]);
		const countries = ref([]);
		const pageId = ref(0);
		const pageImg = ref(null);

		function getData(e) {
			category.value = e
			if(category.value != null) {
				categoryId.value = category.value.id
			}
		}

		onMounted(() => {
			getScenesByType('metro')
			getScenesByType('state')
			getScenesByType('region')
			getScenesByType('country')
			
		})

		function getScenesByType(sceneType) {
			//presentLoading()
			apiClient.get('/api/get/scenes/type/' + sceneType, {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				// Scene
				if(sceneType == 'metro') {
					scenes.value = response.data
				}
				// State
				if(sceneType == 'state') {
					states.value = response.data
				}
				// Region
				if(sceneType == 'region') {
					regions.value = response.data
				}
				// Country
				if(sceneType == 'country') {
					countries.value = response.data
				}

			}).catch(error => {
				console.log(error)  
			});      
		}

		function validatePageForm() {
			// Category
			if(categoryId.value < 1) {
				isPageFormValidated.value = false
			} else {
				// Address Required
				if(addressRequired.value === true) {
					if(streetAddress.value != null && streetAddress.value != '') {
						if(city.value != null && city.value != '') {
							if(state.value != null && state.value != '') {
								if(zipCode.value == null || zipCode.value == '') {
									isPageFormValidated.value = false
								} else {
									// Name
									if(name.value != null && name.value != '') {
										isPageFormValidated.value = true
									} else {
										isPageFormValidated.value = false
									}
								}
							} else {
								isPageFormValidated.value = false
							}
						} else {
							isPageFormValidated.value = false
						}
					} else {
						isPageFormValidated.value = false
					}
				} 
				// Address not required
				else {
					// Name
					if(name.value != null && name.value != '') {
						isPageFormValidated.value = true
					} else {
						isPageFormValidated.value = false
					}
				}
			}
		}

		function storePage() {
			var apiUrl = '/api/post/page'
			if(props.id > 0) {
				apiUrl = '/api/update/page/'+props.id
			}
			presentLoading()
			apiClient.post(apiUrl, 
			{
				category_id: categoryId.value,
				name: name.value, 
				description: description.value,
				street_address: streetAddress.value,
				city: city.value,
				scene_id: sceneId.value,
				state: state.value,
				country: country.value,
				zip_code: zipCode.value,
				url: url.value,
				status: status.value,
			},
			{
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			})
			.then(response => {
				// Success
				if(response.data.message == 'success') {
					pageId.value = response.data.page.id
					pageImg.value = response.data.page.image
					toastMessage.value = 'You have saved a page.'
					openToast()
					step.value = 3
				}
				// Success
				if(response.data.message == 'success-delete') {
					toastMessage.value = 'You have deleted a page.'
					openToast()
					resetForm()
					goBack()
				}
				// Fail 
				if(response.data.message == 'fail') {
					toastMessage.value = 'There was a problem.'
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Validation
				if(response.data.message == 'fail-validation') {
					console.log(response.data.errors)
					toastMessage.value = response.data.errors
					openToast()
					isError.value = true
					errors.value = response.data.errors            
				}
				// Fail Auth
				if(response.data.message == 'fail-auth') {
					toastMessage.value = 'You are not authorized to do this.'
					openToast()
				}
				// Fail Auth
				if(response.data.message == 'fail-slug') {
					toastMessage.value = 'That hashtag is already taken.'
					openToast()
				}
			})
			.catch(error => {
				// credentials didn't match
				isError.value = true
				errors.value = error.response.data.errors
			}); 
		}

		function resetForm() {
			name.value = null
			description.value = null
			streetAddress.value = null
			city.value = null
			sceneId.value = 0
			state.value = null
			country.value = null
			zipCode.value = null
			url.value = null
			status.value = 1
			categoryId.value = 0	
			category.value = []	
			addressRequired.value = false	
		}

		async function presentLoading() {
			const loading = await loadingController
			.create({
				cssClass: 'my-custom-class',
				message: 'Please wait...',
				duration: timeout,
			});

			await loading.present();

			setTimeout(function() {
				loading.dismiss()
			}, timeout);
		}

		async function openToast() {
			const toast = await toastController
			.create({
				//message: '',
				duration: 6000,
				message: toastMessage.value,
				position: 'bottom',
				buttons: [
				{
					icon: 'close',
					role: 'cancel',
					handler: () => {

					}
				}]
			})
			return toast.present();
		}

		function goBack() {
			console.log('test')
		}

		function getStep(e) {
			if(e === 1) { 
				step.value = step.value + 1
			}
		}

		return {
			authUser, category, categoryId, getData, step, name, description, streetAddress, city, sceneId, state, country, zipCode, url, status, validatePageForm, isPageFormValidated, storePage, scenes, states, regions, countries, addressRequired, pageId, pageImg, getStep
		}
	},
	watch: {
		category: {
			handler() {
				if(this.category != null && this.country == 'us') {
					if(this.category.slug == 'venue' || this.category.slug == 'restaurant' || this.category.slug == 'bar') {
						this.addressRequired = true
					} 
				} else {
						this.addressRequired = false
				}
				this.validatePageForm()
				if(this.category != []) {
					this.step = 2
				}
			},
			deep:true
		},
		name: function() {
			this.validatePageForm()
		},
		description: function() {
			this.validatePageForm()
		},
		streetAddress: function() {
			this.validatePageForm()
		},
		city: function() {
			this.validatePageForm()
		},
		state: function() {
			this.validatePageForm()
		},
		country: function() {
			if(this.category != null && this.country == 'us') {
				if(this.category.slug == 'venue' || this.category.slug == 'restaurant' || this.category.slug == 'bar') {
					this.addressRequired = true
				}
			} else {
				this.addressRequired = false
			}
			this.validatePageForm()
		},
		zipCode: function() {
			this.validatePageForm()
		},
		url: function() {
			this.validatePageForm()
		},
		status: function() {
			this.validatePageForm()
		},
	}
});
</script>