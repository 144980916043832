<template>
	<div>
		<!-- Pages -->
		<div v-if="pages.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item v-for="page in pages" :key="page" button detail :href="'/admin-page/'+page.id">
					<div tabindex="0"></div>
					<ion-avatar v-if="page.image != null && page.image != ''" slot="start">
						<img :src="page.image" >
					</ion-avatar>
					{{page.name}}
				</ion-item>
			</ion-list>
		</div>
		<!-- None -->
		<div v-else>
			<ion-item>
				<div tabindex="0"></div>
				There are no pages
			</ion-item>
		</div>
	</div>
</template>

<script>
import { IonList, IonItem, IonAvatar } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'AdminPageList',
	props: [],
	components: {
		IonList, IonItem, IonAvatar
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const pages = ref([]);

		onMounted(() => {
			getPages()
		})

		function getPages() {
			//presentLoading()
			apiClient.get('/api/get/pages', {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				pages.value = response.data
			}).catch(error => {
				console.log(error)  
			});      
		}

		function editPage(id) {
			router.push('/admin-page/'+id)
		}

		return {
			authUser, pages, editPage, router
		}
	},
});
</script>